/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

#TitleHeader{
    font-family: 'Dancing Script', cursive;
    font-size: xx-large;
}
